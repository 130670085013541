<template>
    <v-app>
        <!-- Top Bar -->
        <v-slide-y-transition>
            <v-overlay v-if="welcome" color="white" opacity="1" z-index="999">
                <WelcomeOverlay></WelcomeOverlay>
            </v-overlay>
        </v-slide-y-transition>


        <!-- Top Bar -->
        <v-app-bar
            app
            dark
            color="#444"
        >
            <v-btn v-if="client != 0" icon @click="menu = !menu">
                <v-icon>mdi-menu</v-icon>
            </v-btn>

            <v-spacer></v-spacer>

            <v-img
                alt="Tecnoclub SAT"
                class="shrink mr-2"
                contain
                src="@/assets/tecnoclub.png"
                lazy-src="@/assets/tecnoclub.png"
                transition="fade-transition"
                width="120"
            />
        </v-app-bar>

        <!-- Side Menu -->
        <side-menu  v-if="client != 0" v-model="menu" @logout="logout"></side-menu>

        <!-- Router View -->
        <v-main>
            <router-view @logout="logout"/>
        </v-main>

        <!-- Versión Control -->
        <div class="fixed-bottom-left">
            <v-btn
                class="black--text font-weight-bold"
                color="warning"
                tile
                small
            >
                <v-icon left>mdi-shield-alert</v-icon>
                <span>0.1.0 beta</span>
            </v-btn>
        </div>
    </v-app>
</template>

<script>

import SideMenu from "@/components/SideMenu";
import WelcomeOverlay from "@/components/WelcomeOverlay";
import {mapState} from "vuex";
import api from "@/api";

export default {
    name: 'App',
    components: {
        WelcomeOverlay,
        SideMenu
    },
    data: () => ({
        welcome: true,
        menu: null,
    }),
    computed: {
        ...mapState([
            'client',
            'key',
            'calendar'
        ])
    },
    methods: {
        logout() {
            localStorage.clear()
            this.$store.commit('SET_CREDENTIALS', [0, ''])
            this.$router.push('/login')
        },
        getCalendar() {
            api.GET_CALENDAR()
                .then(r => {
                    if (r.data.length) {
                        this.$store.commit('SET_CALENDAR', r.data)
                        localStorage.setItem('calendar', JSON.stringify(r.data))
                    }
                })
        },
        async getStatus() {
            let date = new Date()
            let weekDay = date.getDay()
            if(weekDay == 6 || weekDay == 0) {
                return 2
            }

            let festivity = false
            this.calendar.forEach(item => {
                if(item.date == this.$moment().format('YYYY-MM-DD')) {
                    this.$store.commit('SET_DAY_NAME', item.name)
                    festivity = true
                }
            })
            if (festivity) { return 3 }

            let now = this.$moment()
            let start1 = this.$moment('09:00', 'HH:mm');
            let end1 = this.$moment('13:00', 'HH:mm');
            let start2 = this.$moment('15:00', 'HH:mm');
            let end2 = this.$moment('18:00', 'HH:mm');

            if (now < start1 || end1 <= now && now < start2 || end2 < now) {
                console.log('Fuera de horario')
                return 2
            }

            let r = await api.GET_STATUS();
            return parseInt(r.data)
        },
        async checkStatus() {
            let status = await this.getStatus()
            this.$store.commit('SET_STATUS', status)
            setTimeout(this.checkStatus, 5000);
        }
    },
    watch: {
        client() {
            this.menu = false
        }
    },
    created() {
        setTimeout(() => this.welcome = false, 5000)
        let c = localStorage.getItem('client')
        let k = localStorage.getItem('key')
        let d = localStorage.getItem('calendar')
        if (d) {
            this.$store.commit('SET_CALENDAR', JSON.parse(d))
        }
        if (c && k) {
            this.$store.commit('SET_CREDENTIALS', [c, k])
        } else {
            this.$router.push('/login')
        }
        this.getCalendar()
        this.checkStatus()

    },

};
</script>

<style>
.fixed-bottom-left {
    bottom: 0px;
    left: 0px;
    position: fixed;
    z-index: 99999;
}
</style>

