<template>
    <v-container>
        <v-row>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="auto">
                <v-form v-model="valid" @submit.prevent="checkLogin">
                    <v-card flat>
                        <v-card-text>
                            <v-img
                                :src="require('../assets/logo.png')"
                                class="mt-3 mb-10"
                                contain
                                height="200"
                            />
                            <v-text-field
                                v-model="client"
                                :rules="clientRules"
                                label="Código de cliente"
                                required
                                :disabled="submited"
                                filled
                                append-icon="mdi-account"
                            ></v-text-field>
                            <v-text-field
                                v-model="key"
                                :rules="keyRules"
                                label="Clave"
                                required
                                :disabled="submited"
                                filled
                                append-icon="mdi-key"
                            ></v-text-field>
                            <div class="text-right mt-3">
                                <v-btn
                                    type="submit"
                                    color="primary"
                                    :disabled="!valid"
                                    :loading="submited"
                                ><v-icon left>mdi-car-door</v-icon>entrar</v-btn>
                            </div>
                            <v-alert v-if="error" type="error">Cliente o Clave incorrectos</v-alert>
                        </v-card-text>
                    </v-card>
                </v-form>
            </v-col>
            <v-spacer></v-spacer>
        </v-row>


    </v-container>
</template>

<script>
import api from "@/api";

export default {
    name: 'LoginView',
    data: () => ({
        valid: false,
        client: '',
        key: '',
        clientRules: [
            v => !!v || '¡Necesitamos saber el código de cliente!',
            v => parseInt(v) > 0 || '¡Solo números!',
        ],
        keyRules: [
            v => !!v || 'Sin clave no se puede entrar...',
        ],
        submited: false,
        error: false
    }),
    methods: {
        checkLogin() {
            this.submited = true
            api.CHECK_LOGIN(this.client, this.key)
                .then(r => {
                    if (r.data['allowed']) {
                        localStorage.setItem('client', this.client)
                        localStorage.setItem('key', this.key)
                        this.$store.commit('SET_CREDENTIALS', [this.client, this.key])
                        this.$router.push('/')
                    } else {
                        this.submited = false
                        this.error = true
                    }
                })
        }
    },
}
</script>
