import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginView from "@/views/LoginView";
import HotlineView from "@/views/HotlineView";
import StatusView from "@/views/StatusView";
import HistoryView from "@/views/HistoryView";
import CalendarView from "@/views/CalendarView";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/status'
  },
  {
    path: '/status',
    name: 'status',
    component: StatusView
  },
  {
    path: '/hotline',
    name: 'hotline',
    component: HotlineView
  },
  {
    path: '/history',
    name: 'history',
    component: HistoryView
  },
  {
    path: '/calendar',
    name: 'calendar',
    component: CalendarView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
