<template>
    <v-container>
        <v-overlay absolute v-if="completed">
            <v-card color="#444" max-width="300">
                <v-card-text>
                    <v-alert type="success">¡Solicitud enviada!</v-alert>
                    <p>
                        El equipo de <b>Tecnoclub</b> te responderá con la mayor brevedad posible...
                    </p>
                    <p class="warning--text" v-if="status > 0">
                        <v-icon small color="warning">mdi-alert</v-icon> <i>Servicio fuera de horario. La solicitud se responderá en el próximo día laborable.</i>
<!--                        <v-icon small color="warning">mdi-alert</v-icon> <i>Si el servicio se encuentra fuera de horario la solicitud se responderá en el próximo día laborable.</i>-->
                    </p>
                    <div class="text-right">
                        <v-btn light to="/history">Aceptar</v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-overlay>
        <v-row>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="auto">
                <v-form
                    ref="form"
                    v-model="valid"
                    @submit.prevent="sendRequest"
                    >
                    <v-card flat width="600">
                        <v-card-text>
                            <h1 class="display-1 mb-5">Solicitud Hotline</h1>
                            <v-text-field
                                v-model="plate"
                                :rules="plateRules"
                                label="Matrícula"
                                required
                                filled
                                append-icon="mdi-car-select"
                            ></v-text-field>
                            <v-textarea
                                v-model="text"
                                :rules="textRules"
                                :counter="200"
                                label="Solicitud"
                                required
                                filled
                                append-icon="mdi-text"
                            ></v-textarea>
                            <div class="text-right mt-3">
                                <v-btn color="primary"
                                       type="submit"
                                       :disabled="!valid"
                                       :loading="submited"
                                ><v-icon left>mdi-send</v-icon>Enviar solicitud</v-btn>
                            </div>
                        </v-card-text>

                    </v-card>
                </v-form>
            </v-col>
            <v-spacer></v-spacer>
        </v-row>
    </v-container>
</template>

<script>

import api from "@/api";
import {mapState} from "vuex";

export default {
    name: 'HotlineView',
    data: () => ({
        valid: false,
        plate: '',
        plateRules: [
            v => !!v || 'La matrícula es obligatoria',
            v => v.length > 4 || 'La matrícula debe tener más de 4 dígitos',
        ],
        text: '',
        textRules: [
            v => !!v || 'La solicitud es obligatoria',
            v => v.length <= 200 || 'Solo se permiten 140 carácteres',
        ],
        submited: false,
        completed: false,
    }),
    computed: {
        ...mapState([
            'client',
            'key',
            'status'
        ])
    },
    methods: {
        sendRequest() {
            this.submited = true
            api.HOTLINE_REQUEST(this.client, this.key, this.plate, this.text)
                .then(r => {
                    console.log(r.data)
                    if (r.data['allowed']) {
                        this.submited = false
                        this.completed = true
                    } else {
                        this.$emit('logout')
                    }
                })
        },
    },
    watch: {
        plate() {
            this.plate = this.plate.toUpperCase()
        },
    }
}
</script>
