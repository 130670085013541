<template>
    <v-container class="black--text">
        <v-row class="text-center">
            <v-col cols="12">
                <v-img
                    :src="require('../assets/logo.png')"
                    :lazy-src="require('../assets/logo.png')"
                    class="my-3"
                    contain
                    height="300"
                />
            </v-col>

            <v-col>
                <h1 class="display-1 font-weight-bold ">
                    Tecnoclub APP
                </h1>
                <h5>BETA</h5>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
export default {
    name: 'WelcomeOverlay',
}
</script>
