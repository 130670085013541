<template>
    <v-card flat>
        <v-card-text>
            <v-row>
                <v-col cols="12" lg="4" v-for="(m,index) in months" :key="index">
                    <v-sheet>
                        <h3 class="text-center">
                            {{ getMonthName(months[index]) }}
                        </h3>
                        <v-calendar
                            :start="m"
                            type="month"
                            :weekdays="[1, 2, 3, 4, 5]"
                            :events="events"
                        ></v-calendar>
                    </v-sheet>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import {mapState} from "vuex";

export default {
    name: "CalendarView",
    computed: {
        months() {
            let months = []
            for (let i = 1; i < 13; i++) {
                let m = (i > 9) ? i : '0' + i
                months.push('2022-' + m + '-01')
            }
            return months
        },
        events() {
            let events = []
            this.calendar.forEach(date => {
                events.push({
                    name: date.name,
                    start: new Date(date.date),
                    color: 'primary',
                })
            })
            return events
        },
        ...mapState([
            'calendar',
        ])
    },
    methods: {
        getMonthName(dtStr) {
            const date = new Date(dtStr)
            if (date.toString() !== 'Invalid Date')
                return date.toLocaleString('es-es', {month: 'long'}).toLocaleUpperCase()
        },
    }
}
</script>

<style scoped>

</style>
