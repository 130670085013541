<template>
    <v-navigation-drawer
        v-model="drawer"
        color="#222"
        dark
        app
    >
        <template v-slot:prepend>
            <v-list-item two-line>
                <v-list-item-avatar>
                    <img src="@/assets/logo.png">
                </v-list-item-avatar>

                <v-list-item-content>
                    <v-list-item-title>Tecnoclub SAT</v-list-item-title>
                    <v-list-item-subtitle>Cliente: {{ client }}
                        <v-btn icon @click="logout">
                            <v-icon color="primary" small>mdi-exit-to-app</v-icon>
                        </v-btn>
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </template>
        <v-divider></v-divider>
        <v-list
            nav
            dense
        >
            <v-list-item-group>
                <v-list-item v-for="(item, index) in menu"
                             :key="index"
                             :to="item.url">
                    <v-list-item-avatar :color="getColor(item.url)">
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-title :class="getColor(item.url) + '--text'">{{ item.title }}</v-list-item-title>
                </v-list-item>
            </v-list-item-group>
        </v-list>


    </v-navigation-drawer>
</template>

<script>
import router from "@/router";
import {mapState} from "vuex";

export default {
    name: "SideMenu",
    props: ['value'],
    data() {
        return {
            drawer: null,
            menu: [
                {
                    icon: 'mdi-face-agent',
                    title: 'Estado del servicio',
                    url: '/status'
                },
                {
                    icon: 'mdi-car-info',
                    title: 'Realizar consulta',
                    url: '/hotline',
                },
                {
                    icon: 'mdi-book-search',
                    title: 'Historial de consultas',
                    url: '/history',
                },
                {
                    icon: 'mdi-calendar-check',
                    title: 'Calendario laboral',
                    url: '/calendar',
                },
            ]
        }
    },
    computed: {
        ...mapState([
            'client',
            'key'
        ])
    },
    methods: {
        go(url) {
            if (!this.$route.path.includes(url)) {
                router.push(url);
            }
        },
        getColor(url) {
            return this.$route.path.includes(url) ? 'primary' : '#444'
        },
        logout() {
            this.$emit('logout')
        }
    },
    watch: {
        value(v) {
            this.drawer = v
        },
        drawer(v) {
            this.$emit('input', v)
        }
    }
}
</script>

<style scoped>
.bg-active {
    background-color: black;
    color : white !important;
}
</style>





