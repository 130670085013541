import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        client:0,
        key:'',
        calendar:[],
        status: 0,
        dayName:'',
    },
    getters: {},
    mutations: {
        SET_CREDENTIALS(state, data) {
            state.client = data[0]
            state.key = data[1]
        },
        SET_CALENDAR(state, data) {
            state.calendar = data
        },
        SET_STATUS(state, data) {
            state.status = data
        },
        SET_DAY_NAME(state, data) {
            state.dayName = data
        }
    },
    actions: {},
    modules: {}
})
