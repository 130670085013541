<template>
    <v-container>
        <v-alert v-if="status==0" type="success">¡Servicio de <b>Tecnoclub</b> activo!<br><small>Teléfono: <b>93 733 86 74</b></small></v-alert>
        <v-alert v-if="status==1" type="warning">¡Disculpen las molestias!
            <br><small>Estamos sufriendo algunos problemas técnicos en nuestro servicio...</small>

        </v-alert>
        <v-alert v-if="status==2" type="error">¡<b>Tecnoclub</b> está fuera de horario!<br><small>L-V De 9 a 13 h y de
            15 a 18 h</small></v-alert>
        <v-alert v-if="status==3" type="error">¡Servicio cerrado por festividad!
            <br><small>{{ dayName }}</small></v-alert>
<!--        <div class="text-right">-->
<!--            <v-btn color="primary" to="/hotline">Enviar consulta hotline</v-btn>-->
<!--        </div>-->

        <v-row class="overflow-hidden pb-2 px-2">
            <v-col cols="6" class="px-2" v-for="(m, index) in menu" :key="index">
                <v-btn height="100" width="100%" :to="m.url" class="text-center">
                    <v-row>
                        <v-col cols="12" class="pb-0">
                            <v-icon x-large >{{ m.icon }}</v-icon>
                        </v-col>
                        <v-col cols="12">
                            <small>{{m.title}}</small>
                        </v-col>
                    </v-row>

                </v-btn>
            </v-col>
        </v-row>



    </v-container>
</template>

<script>
import {mapState} from "vuex";

export default {
    name: "StatusView",
    data: () => ({
        menu: [
            {
                icon: 'mdi-car-info',
                title: 'Realizar consulta',
                url: '/hotline',
            },
            {
                icon: 'mdi-book-search',
                title: 'Historial consultas',
                url: '/history',
            },
            {
                icon: 'mdi-calendar-check',
                title: 'Calendario laboral',
                url: '/calendar',
            },
        ]
    }),
    computed: {
        ...mapState([
            'status',
            'dayName'
        ])
    }

}
</script>

<style scoped>

</style>
