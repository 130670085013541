<template>
    <v-container>
        <v-simple-table fixed-header height="85vh">
            <template v-slot:default>
                <thead>
                <tr>
                    <th class="text-left">
                        Fecha
                    </th>
                    <th class="text-left">
                        Matrícula
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr
                    v-for="(item, index) in history"
                    :key="index"
                >
                    <td>{{ item.date }}</td>
                    <td>{{ item.plate }}</td>
                </tr>
                </tbody>
            </template>
        </v-simple-table>
    </v-container>
</template>

<script>
import {mapState} from "vuex";
import api from "@/api";

export default {
    name: "HistoryView",
    data: () => ({
        history: [],
    }),
    computed: {
        ...mapState([
            'client',
            'key'
        ])
    },
    methods: {
        getHistory() {
            api.GET_HISTORY(this.client, this.key)
            .then(r => {
                if (r.data['allowed']) {
                    this.history = r.data['items'];
                } else {
                    this.$emit('logout')
                }

            })
        }
    },
    mounted() {
        this.getHistory()
    }

}
</script>

<style scoped>

</style>
