import axios from "axios";

const API_URL = 'https://api.tecnoclub.org/app/'

export default {


    // MAIN
    CHECK_LOGIN(client, key) {
        return axios.post(API_URL + 'checkLogin.php', {
            client: client,
            key: key
        })
    },

    HOTLINE_REQUEST(client, key, plate, text) {
        return axios.post(API_URL+'hotlineRequestBridge.php', {
            client: client,
            key: key,
            plate: plate,
            text: text
        })
    },

    GET_CALENDAR() {
        return axios.get(API_URL+'getCalendar.php')
    },

    GET_STATUS() {
        return axios.get(API_URL+'getStatus.php')
    },

    GET_HISTORY(client, key) {
        return axios.post(API_URL+'getHistory.php', {
            client: client,
            key: key
        })
    }

}
